import { ApolloError } from '@apollo/client';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, Fragment, ReactNode, useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import CopyToClipboard from 'primitives/CopyToClipboard';
import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from 'primitives/Table';
import TextField from 'primitives/TextField';

import acceptanceStatusToReadable from 'utils/enums/acceptance-status-to-readable';
import companyEventTypeToReadable from 'utils/enums/company-event-type-to-readable';
import countryCodeToReadable from 'utils/enums/country-code-to-readable';
import dealFlowToReadable from 'utils/enums/deal-flow-to-readable';
import dealTypeEnumToReadable from 'utils/enums/deal-type-to-readable';
import fundingRoundToReadable from 'utils/enums/funding-round-to-readable';
import fundingSourceToReadable from 'utils/enums/funding-source-to-readable';
import industryToReadable from 'utils/enums/industry-to-readable';
import investmentEntityDocumentTypeToReadable from 'utils/enums/investment-entity-document-type-to-readable';
import investmentEntityTypeToReadable from 'utils/enums/investment-entity-type-to-readable';
import stageToReadable from 'utils/enums/stage-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import syndicateInviteTypeToReadable from 'utils/enums/syndicate-invite-type-to-readable';
import syndicateTypeToReadable from 'utils/enums/syndicate-type-to-readable';
import { fixToTwoLocalPrice, formatNumber } from 'utils/format-helper';

import theme from './theme.module.scss';

type DataType =
  | 'DATE'
  | 'DATETIME'
  | 'STRING'
  | 'COPY_STRING'
  | 'CURRENCY'
  | 'IMAGE'
  | 'NUMBER'
  | 'STATUS'
  | 'BOOLEAN'
  | 'SELECT'
  | 'LINK'
  | 'FUNDING_SOURCE'
  | 'FUNDING_ROUND'
  | 'STAGE'
  | 'INVESTMENT_ENTITY_TYPE'
  | 'ASSET_TYPE'
  | 'DEAL_TYPE'
  | 'COMPANY_EVENT_TYPE'
  | 'SYNDICATE_TYPE'
  | 'INVESTMENT_ENTITY_DOCUMENT_TYPE'
  | 'SYNDICATE_INVITE_TYPE'
  | 'DEAL_FLOW'
  | 'ACCEPTANCE_STATUS'
  | 'INDUSTRY'
  | 'COUNTRY'
  | 'PERCENTAGE'
  | 'MULTIPLE'
  | 'IMAGE_WITH_NAME'
  | 'MONTH';

interface Column {
  label: string;
  fieldName: string;
  type?: DataType;
}

const DataTableHead: FC<{
  columns: Column[];
  isSelected: boolean;
  onSelectAll: (value: any) => void;
}> = ({ columns, isSelected, onSelectAll }) => (
  <TableHead>
    <TableHeadRow>
      {columns.map((c, i) => (
        <TableHeadCell
          key={c.fieldName + c.label}
          isRounded={i === 0 ? 'left' : i === columns.length - 1 ? 'right' : undefined}
          align={
            c.type === 'CURRENCY' || c.type === 'NUMBER' || c.type === 'PERCENTAGE'
              ? 'right'
              : 'left'
          }
        >
          {c.type === 'SELECT' ? (
            <Checkbox
              size="small"
              sx={{
                height: '24px',
                width: '24px',
              }}
              value={isSelected}
              onChange={e => onSelectAll(e.target.checked)}
            />
          ) : (
            c.label
          )}
        </TableHeadCell>
      ))}
    </TableHeadRow>
  </TableHead>
);

const DataTableCell: FC<{
  value: any;
  type?: DataType;
  onSelect?: (value: any) => void;
  aggregate?: boolean;
}> = ({ value, type, onSelect = () => {}, aggregate }) => {
  if (aggregate) {
    switch (type) {
      case 'CURRENCY':
        return (
          <TableCell className="!py-1 font-semibold text-xs !text-indigo-400" align="right">
            {value !== undefined && value !== null ? `₹ ${fixToTwoLocalPrice(value)}` : '-'}
          </TableCell>
        );
      default:
        return (
          <TableCell className="!py-1 font-semibold text-xs !text-indigo-400" align="left">
            {value}
          </TableCell>
        );
    }
  }

  switch (type) {
    case 'SELECT':
      return (
        <TableCell align="left" onClick={e => e.stopPropagation()}>
          <Checkbox
            size="small"
            sx={{
              height: '24px',
              width: '24px',
            }}
            checked={value}
            onChange={e => onSelect(e.target.checked)}
          />
        </TableCell>
      );
    case 'CURRENCY':
      return (
        <TableCell align="right">
          {value !== undefined && value !== null ? `₹ ${fixToTwoLocalPrice(value)}` : '-'}
        </TableCell>
      );
    case 'DATE':
      return <TableCell align="left">{value ? dayjs(value).format('D MMMM YYYY') : ' '}</TableCell>;
    case 'MONTH':
      return <TableCell align="left">{value ? dayjs(value).format('MMMM YYYY') : ' '}</TableCell>;
    case 'DATETIME':
      return <TableCell align="left">{dayjs(value).format('h:mm A, D MMMM YYYY')}</TableCell>;
    case 'LINK':
      return (
        <TableCell align="left">
          <a
            href={value}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer text-indigo-700 hover:underline"
          >
            Link
          </a>
        </TableCell>
      );
    case 'IMAGE':
      return (
        <TableCell>
          <div
            style={{
              backgroundImage: `url("${value}")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundColor: '#fff',
              height: '36px',
              width: '36px',
              margin: '8px',
              borderRadius: '4px',
            }}
          />
        </TableCell>
      );
    case 'NUMBER':
      return <TableCell align="right">{formatNumber(value)}</TableCell>;
    case 'PERCENTAGE':
      return <TableCell align="right">{value ? `${value.toFixed(2)}%` : '-'}</TableCell>;
    case 'BOOLEAN':
      return (
        <TableCell align="center">
          {value ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" />
          )}
        </TableCell>
      );
    case 'STATUS':
      const { label, color } = statusEnumToReadable(value);
      return (
        <TableCell align="left">
          {value ? (
            <Badge label={label} color={color} showIndicator={true} />
          ) : (
            <TableCell align="right"> </TableCell>
          )}
        </TableCell>
      );
    case 'COPY_STRING':
      return (
        <TableCell align="left">
          <div className="pr-2 relative group">
            <span className="text-gray-500">{value}</span>
            <CopyToClipboard
              value={value}
              className="absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover:block"
            />
          </div>
        </TableCell>
      );
    case 'FUNDING_SOURCE':
      const { label: fundingSourceLabel, color: fundingSourceColor } =
        fundingSourceToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={fundingSourceLabel} color={fundingSourceColor} />
        </TableCell>
      );
    case 'FUNDING_ROUND':
      const { label: fundingRoundLabel, color: fundingRoundColor } = fundingRoundToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={fundingRoundLabel} color={fundingRoundColor} />
        </TableCell>
      );
    case 'STAGE':
      const { label: stageLabel, color: stageColor } = stageToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={stageLabel} color={stageColor} />
        </TableCell>
      );
    case 'INVESTMENT_ENTITY_TYPE':
      const { label: investmentEntityTypeLabel, color: investmentEntityTypeColor } =
        investmentEntityTypeToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={investmentEntityTypeLabel} color={investmentEntityTypeColor} />
        </TableCell>
      );
    case 'DEAL_TYPE':
      const { label: dealTypeLabel, color: dealTypeColor } = dealTypeEnumToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={dealTypeLabel} color={dealTypeColor} />
        </TableCell>
      );
    case 'COMPANY_EVENT_TYPE':
      const { label: companyEventTypeLabel, color: companyEventTypeColor } =
        companyEventTypeToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={companyEventTypeLabel} color={companyEventTypeColor} />
        </TableCell>
      );
    case 'SYNDICATE_TYPE':
      const { label: syndicateTypeLabel, color: syndicateTypeColor } =
        syndicateTypeToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={syndicateTypeLabel} color={syndicateTypeColor} />
        </TableCell>
      );
    case 'INVESTMENT_ENTITY_DOCUMENT_TYPE':
      const { label: investmentEntityDocumentTypeLabel, color: investmentEntityDocumentTypeColor } =
        investmentEntityDocumentTypeToReadable(value);
      return (
        <TableCell align="left">
          <Badge
            label={investmentEntityDocumentTypeLabel}
            color={investmentEntityDocumentTypeColor}
          />
        </TableCell>
      );
    case 'SYNDICATE_INVITE_TYPE':
      const { label: syndicateInviteTypeLabel, color: syndicateInviteTypeColor } =
        syndicateInviteTypeToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={syndicateInviteTypeLabel} color={syndicateInviteTypeColor} />
        </TableCell>
      );
    case 'DEAL_FLOW':
      const { label: dealFlowLabel, color: dealFlowColor } = dealFlowToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={dealFlowLabel} color={dealFlowColor} />
        </TableCell>
      );
    case 'ACCEPTANCE_STATUS':
      const { label: acceptanceStatusLabel, color: acceptanceStatusColor } =
        acceptanceStatusToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={acceptanceStatusLabel} color={acceptanceStatusColor} />
        </TableCell>
      );
    case 'INDUSTRY':
      const { label: industryLabel, color: industryColor } = industryToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={industryLabel} color={industryColor} />
        </TableCell>
      );
    case 'COUNTRY':
      const { label: countryLabel, color: countryColor } = countryCodeToReadable(value);
      return (
        <TableCell align="left">
          <Badge label={countryLabel} color={countryColor} />
        </TableCell>
      );
    case 'MULTIPLE':
      const multipleValue = Math.round(value * 100) / 100;

      if (value === undefined || value === null) {
        return <TableCell align="right">-</TableCell>;
      }

      return (
        <TableCell align="right">
          <div
            className={classNames(
              'inline-flex items-baseline rounded-md px-2.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0',
              {
                'bg-green-100 text-green-800': multipleValue > 1,
                'bg-red-100 text-red-800': multipleValue < 1,
                'bg-gray-100 text-gray-800': multipleValue === 1,
              }
            )}
          >
            {multipleValue > 1 && (
              <ArrowUpIcon
                className="-ml-1 mr-0.5 h-3.5 w-3.5 flex-shrink-0 self-center text-green-500"
                aria-hidden="true"
              />
            )}
            {multipleValue < 1 && (
              <ArrowDownIcon
                className="-ml-1 mr-0.5 h-3.5 w-3.5 flex-shrink-0 self-center text-red-500"
                aria-hidden="true"
              />
            )}
            {value.toFixed(2)}x
          </div>
        </TableCell>
      );
    case 'IMAGE_WITH_NAME':
      return (
        <TableCell>
          <div className="flex items-center">
            <div>
              <div
                style={{
                  backgroundImage: `url("${value.image}")`,
                  backgroundRepeat: 'no-repeat',
                }}
                className="rounded-lg h-8 w-8 bg-gray-50 bg-center bg-contain bg-no-repeat"
              />
            </div>
            <span className="ml-3 text-gray-800">{value.name}</span>
          </div>
        </TableCell>
      );
    default:
      return <TableCell align="left">{value}</TableCell>;
  }
};

const DataTable: FC<{
  data: any[];
  aggregatedData?: any;
  columns: Column[];
  searchFields?: string[];
  onLoadMore?: () => void;
  filterLoading?: boolean;
  paginationLoading?: boolean;
  hasNextPage?: boolean;
  onClick?: (dataItem: any) => void;
  bulkSelectActions?: {
    label: string;
    icon?: ReactNode;
    action: (selectedItems: any[]) => Promise<any>;
    loading?: boolean;
    error?: ApolloError;
  }[];
  onSelect?: (selectedItems: any[]) => void;
  emptyListImage?: string;
  emptyListTitle?: string;
  emptyListDescription?: string;
}> = ({
  data,
  aggregatedData,
  columns,
  searchFields,
  onLoadMore,
  filterLoading = false,
  paginationLoading = false,
  hasNextPage = false,
  onClick,
  bulkSelectActions,
  onSelect,
  emptyListImage,
  emptyListTitle,
  emptyListDescription,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  function getValueByFieldName(fieldName, obj) {
    // Example: fieldName "group.name" looks for obj[group][name]
    if (!fieldName) return obj;
    return fieldName.split('.').reduce((acc, curr) => {
      if (!acc) return null;
      return acc[curr];
    }, obj);
  }

  function updateSelectedItems(items: string[]) {
    setSelectedItems(items);
    onSelect && onSelect(items);
  }

  return (
    <div>
      {searchFields && searchFields.length ? (
        <div className="mb-2">
          <TextField
            placeholder="Search"
            onChange={e => setSearchTerm(e.target.value.toLowerCase())}
          />
        </div>
      ) : null}
      <div className="flow-root">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <TableContainer>
            <Table>
              <DataTableHead
                columns={columns}
                isSelected={selectedItems.length > 0}
                onSelectAll={checked => {
                  if (!checked) {
                    updateSelectedItems([]);
                    return;
                  }
                  updateSelectedItems(
                    data.map(item => getValueByFieldName(columns[0].fieldName, item))
                  );
                }}
              />
              <TableBody>
                {aggregatedData && (
                  <TableRow className="bg-gray-50">
                    {columns.map(c => (
                      <DataTableCell
                        key={`aggregate-${c.fieldName}`}
                        type={c.type}
                        value={
                          c.fieldName in aggregatedData ? aggregatedData[c.fieldName] : undefined
                        }
                        aggregate
                      />
                    ))}
                  </TableRow>
                )}
                {!filterLoading &&
                  data
                    .filter(d => {
                      if (searchFields && searchFields.length) {
                        return searchFields.some(sf =>
                          getValueByFieldName(sf, d).toLowerCase().includes(searchTerm)
                        );
                      }
                      return true;
                    })
                    .map(d => (
                      <TableRow onClick={() => (onClick ? onClick(d) : null)} key={d.id}>
                        {columns.map(c => (
                          <DataTableCell
                            key={c.fieldName + c.label}
                            type={c.type}
                            value={
                              c.type === 'SELECT'
                                ? selectedItems.includes(getValueByFieldName(c.fieldName, d))
                                : getValueByFieldName(c.fieldName, d)
                            }
                            onSelect={checked => {
                              if (checked) {
                                const newSelectedItems = [
                                  ...selectedItems,
                                  getValueByFieldName(c.fieldName, d),
                                ];
                                updateSelectedItems(newSelectedItems);
                                return;
                              }

                              const newSelectedItems = selectedItems.filter(
                                item => item !== getValueByFieldName(c.fieldName, d)
                              );
                              updateSelectedItems(newSelectedItems);
                            }}
                          />
                        ))}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {filterLoading ? (
              <div className="py-5">
                <LoadingIndicator />
              </div>
            ) : null}
            {data.length < 1 && !filterLoading ? (
              <EmptyState
                title={emptyListTitle || 'No results found'}
                description={emptyListDescription || 'Try adjusting your search or filters'}
                image={emptyListImage}
                bordered={false}
              />
            ) : null}
          </TableContainer>
          {selectedItems.length && bulkSelectActions ? (
            <div className={theme.bulkActionsContainer}>
              <div className={theme.top}>
                <span className={theme.actionLabel}>{`${selectedItems.length} item${
                  selectedItems.length > 1 ? 's' : ''
                } selected`}</span>
                <Button variant="secondary" onClick={() => updateSelectedItems([])}>
                  Close
                </Button>
              </div>
              <div className={theme.bottom}>
                {bulkSelectActions?.map((action, index) => {
                  return (
                    <Fragment key={`bulk-action-${index}`}>
                      {action.error ? <ErrorMessage error={action.error} /> : null}
                      <Button
                        loading={action.loading}
                        // endIcon={action.icon} // TODO: Add icon support
                        onClick={() =>
                          action.action(selectedItems).then(() => updateSelectedItems([]))
                        }
                      >
                        {action.label}
                      </Button>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          ) : null}

          {onLoadMore && hasNextPage && !filterLoading ? (
            <div className={theme.paginationContainer}>
              <Button loading={paginationLoading} onClick={onLoadMore}>
                Load More
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
