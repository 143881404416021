import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { fixToTwoLocalPrice, formatCurrency } from 'utils/format-helper';

const DEAL_PROGRESS_QUERY = gql(`
  query GetDealProgressChart($id: ID!) {
    deal(id: $id) {
      id
      allocation
      closingDate
      status
      statistics {
        totalCommittedAmount
        totalWiredAmount
      }
    }
  }
`);

const DealProgressChart: FC<{ dealId: string }> = ({ dealId }) => {
  const { loading, error, data, refetch } = useQuery(DEAL_PROGRESS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  function isFinalized(status: DealStatusEnumType) {
    switch (status) {
      case DealStatusEnumType.Pooling:
      case DealStatusEnumType.Cancelled:
      case DealStatusEnumType.Closed:
      case DealStatusEnumType.Raising:
      case DealStatusEnumType.UnderLegalReview:
      case DealStatusEnumType.UnderOpsReview:
      case DealStatusEnumType.Funded:
        return false;
      case DealStatusEnumType.Wired:
      case DealStatusEnumType.Exited:
        return true;
      default:
        return false;
    }
  }

  const { label, color } = statusEnumToReadable(deal.status);

  return (
    <div className="p-4 border border-gray-100 rounded-lg">
      <div className="mb-4">
        <Badge label={label} color={color} showIndicator size="lg" />
      </div>
      {deal.status !== DealStatusEnumType.Exited && (
        <>
          <Statistics
            statistics={[
              ...(!isFinalized(deal.status)
                ? [
                    {
                      label: 'Total Committed Amount',
                      value: deal.statistics.totalCommittedAmount,
                      type: 'CURRENCY' as const,
                    },
                  ]
                : []),
              {
                label: isFinalized(deal.status) ? 'Wired to Company' : 'Ready to Wire',
                value: deal.statistics.totalWiredAmount,
                type: 'CURRENCY' as const,
              },
              {
                label: 'Target Raise',
                value: deal.allocation,
                type: 'CURRENCY' as const,
              },
              {
                label: 'Closing Date',
                value: deal.closingDate,
                type: 'DATE' as const,
              },
            ]}
          />

          <div className="w-full mt-6 p-4 border border-gray-100 rounded-lg">
            <div className="flex overflow-hidden rounded-lg bg-gray-200">
              <div
                className={`h-4 bg-indigo-500`}
                style={{ width: `${(deal.statistics.totalWiredAmount / deal.allocation) * 100}%` }}
              />
              <div
                className={`h-4 bg-yellow-400`}
                style={{
                  width: `${(deal.statistics.totalCommittedAmount / deal.allocation) * 100}%`,
                }}
              />
            </div>
            <div className="mt-4 flex gap-x-2">
              <Badge
                label={`${
                  isFinalized(deal.status) ? 'Wired' : 'Ready to Wire'
                } ${fixToTwoLocalPrice(
                  (deal.statistics.totalWiredAmount / deal.allocation) * 100
                )}%`}
                color="indigo"
                showIndicator
              />
              {!isFinalized(deal.status) && (
                <Badge
                  label={`Commitments ${fixToTwoLocalPrice(
                    (deal.statistics.totalCommittedAmount / deal.allocation) * 100
                  )}%`}
                  color="yellow"
                  showIndicator
                />
              )}
            </div>
            {deal.statistics.totalWiredAmount > deal.allocation && (
              <p className="text-sm text-indigo-700 font-medium mt-2">
                Over allocation by{' '}
                {formatCurrency(deal.statistics.totalWiredAmount - deal.allocation)}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DealProgressChart;
