import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

// import EmailPreferences from './EmailPreferences';
import NotificationPreferences from './NotificationPreferences';

const USER_QUERY = gql(`
  query getUserNotificationSettings {
    dashboard {
      id
      user {
        id
        notificationPreferences {
          id
          emailEnabled
        }
        emailPreferences {
          id
          newSyndicateApplications
          portfolioCompanyUpdates
          syndicateSuggestions
          monthlyNewsDigest
          messages
          dealProgressUpdates
          dealInvites
          dealInviteUpdates
          dealInviteReminders
        }
      }
    }
  }
`);

const NotificationSettingsPage: FC = () => {
  const { loading, error, data, refetch } = useQuery(USER_QUERY);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;

  if (!user.notificationPreferences || !user.emailPreferences)
    return <ErrorMessage title="No email or notification preferences found" refetch={refetch} />;

  const { emailEnabled } = user.notificationPreferences;
  // const {
  //   newSyndicateApplications,
  //   portfolioCompanyUpdates,
  //   syndicateSuggestions,
  //   monthlyNewsDigest,
  //   messages,
  //   dealProgressUpdates,
  //   dealInvites,
  //   dealInviteUpdates,
  //   dealInviteReminders,
  // } = user.emailPreferences;

  return (
    <>
      <NotificationPreferences id={user.id} emailEnabled={emailEnabled} />
      {/* <EmailPreferences
        id={user.id}
        newSyndicateApplications={newSyndicateApplications}
        syndicateSuggestions={syndicateSuggestions}
        monthlyNewsDigest={monthlyNewsDigest}
        dealProgressUpdates={dealProgressUpdates}
        dealInviteReminders={dealInviteReminders}
        dealInvites={dealInvites}
        dealInviteUpdates={dealInviteUpdates}
        portfolioCompanyUpdates={portfolioCompanyUpdates}
        messages={messages}
      /> */}
    </>
  );
};

export default NotificationSettingsPage;
