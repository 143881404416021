import classNames from 'classnames';

export default function FilterBar({
  title,
  filters,
  currentValue,
  onSelect,
}: {
  title?: string;
  filters: { label: string; value?: string | boolean | string[] }[];
  currentValue: string | boolean | string[] | undefined | null;
  onSelect: (value?: string | boolean | string[]) => void;
}) {
  function valuesAreEqual(
    filterValue: string | boolean | string[] | undefined,
    currentValue: string | boolean | string[] | undefined | null
  ): boolean {
    if (Array.isArray(filterValue) && Array.isArray(currentValue)) {
      // Both are arrays, compare as arrays
      return (
        filterValue.length === currentValue.length &&
        filterValue.every((value, index) => value === currentValue[index])
      );
    } else if (typeof filterValue === 'string' && typeof currentValue === 'string') {
      // Both are strings, compare as strings
      return filterValue === currentValue;
    } else if (typeof filterValue === 'boolean' && typeof currentValue === 'boolean') {
      // Both are booleans, compare as booleans
      return filterValue === currentValue;
    } else {
      // Different types or one is undefined/null, return false
      return false;
    }
  }

  return (
    <div className="flex items-center gap-2">
      {title && <span className="text-md font-medium text-gray-900">{title}: </span>}
      {filters.map(filter => {
        return (
          <div
            className={classNames(
              'cursor-pointer px-2 py-1 rounded-md text-sm bg-gray-50 text-gray-800 hover:bg-gray-100',
              valuesAreEqual(filter.value, currentValue) && 'bg-indigo-100 text-indigo-700'
            )}
            key={`${title}-${filter.label}`}
            onClick={() => onSelect(filter.value)}
          >
            {filter.label}
          </div>
        );
      })}
    </div>
  );
}
