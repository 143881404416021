import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import Alert from 'primitives/Alert';

const UPDATE_NOTIFICATION_PREFERENCES_MUTATION = gql(`
  mutation UpdateUserNotificationPreferences(
    $id: ID!
    $emailEnabled: Boolean!
  ) {
    updateUserNotificationPreferences(
      id: $id
      emailEnabled: $emailEnabled
    ) {
      id
      emailEnabled
    }
  }
`);

const NotificationPreferences: FC<{
  id: string;
  emailEnabled: boolean;
}> = ({ id, emailEnabled }) => {
  const [updateUserNotificationPreferences, { loading, error }] = useMutation(
    UPDATE_NOTIFICATION_PREFERENCES_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUserNotificationPreferences({
          variables: {
            id,
            emailEnabled: data.emailEnabled,
          },
        });
      }}
      title="Notification Preferences"
    >
      <FormInput
        fullWidth
        type="switch"
        fieldName="emailEnabled"
        label="Email Enabled"
        defaultValue={emailEnabled}
      />
      <Alert
        type="info"
        title="This will not turn off emails for important communications sent by AngelList India."
      />
    </FormPanelWithReadMode>
  );
};

export default NotificationPreferences;
