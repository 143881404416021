import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrency } from 'utils/format-helper';

const FUND_CLOSINGS_QUERY = gql(`
  query fetchFundClosingsForUnitStatement($id: ID!, $cursor: ID, $limit: Int) {
    investmentEntity(id: $id) {
      id
      fundClosings(filters: { statuses: [ACTIVE] }, cursor: $cursor, limit: $limit) {
        nodes {
          id
          fund {
            id
            name
          }
          setupFee
          amount
          wiredAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FUND_INVESTMENTS_QUERY = gql(`
  query fetchFundInvestmentsForFundClosing($id: ID!) {
    fundClosing(id: $id) {
      id
      investments(filters: { statuses: [ACTIVE] }) {
        nodes {
          id
          deal {
            id
            schemeName
            companyInformation {
              company {
                id
                image
                name
              }
            }
          }
          numberOfUnits
          amount
          statistics {
            netValue
          }
        }
      }
    }
  }
`);

const FundInvestments: FC<{
  fundClosingId: string;
}> = ({ fundClosingId }) => {
  const { loading, error, data, refetch } = useQuery(FUND_INVESTMENTS_QUERY, {
    variables: {
      id: fundClosingId,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundInvestments = data.fundClosing.investments.nodes;

  if (!fundInvestments.length) return null;

  return (
    <DataTable
      data={fundInvestments}
      columns={[
        {
          label: 'Company',
          fieldName: 'deal.companyInformation.company',
          type: 'IMAGE_WITH_NAME',
        },
        {
          label: 'Units Type',
          fieldName: 'deal.schemeName',
        },
        {
          label: 'No. of Units',
          fieldName: 'numberOfUnits',
          type: 'NUMBER',
        },
        {
          label: 'Investment',
          fieldName: 'amount',
          type: 'CURRENCY',
        },
        {
          label: 'Estimated FMV',
          fieldName: 'statistics.netValue',
          type: 'CURRENCY',
        },
      ]}
    />
  );
};

const FundClosings: FC<{
  investmentEntityId: string;
}> = ({ investmentEntityId }) => {
  const { loading, error, data, refetch } = useQuery(FUND_CLOSINGS_QUERY, {
    variables: {
      limit: 10,
      id: investmentEntityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundClosings = data.investmentEntity.fundClosings.nodes;

  if (!fundClosings.length) return null;

  function stat(label, value) {
    return (
      <div key={label} className="p-3">
        <dt className="text-xs font-normal text-gray-900">{label}</dt>
        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-sm font-medium text-indigo-600">{value}</div>
        </dd>
      </div>
    );
  }

  return (
    <>
      <h2 className="mt-8 text-xl font-bold text-center">Fund Investments</h2>
      <p className="text-gray-600 text-sm mb-4 text-center">
        As of {dayjs().format('D MMMM YYYY')}
      </p>
      {fundClosings.map(fundClosing => (
        <div className="mb-16 mx-auto max-w-4xl">
          <dl className="my-3 flex gap-5 bg-white rounded-lg shadow-sm ring-1 ring-gray-200">
            {stat('Fund Name', fundClosing.fund.name)}
            {stat('Invested Amount', formatCurrency(fundClosing.amount))}
            {stat('Setup Fee', formatCurrency(fundClosing.setupFee))}
            {stat('Investment Date', dayjs(fundClosing.wiredAt).format('DD MMMM YYYY'))}
          </dl>
          <FundInvestments fundClosingId={fundClosing.id} />
        </div>
      ))}
    </>
  );
};

export default FundClosings;
