import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundingSourceEnumType } from '__generated__/graphql';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrencyToShort } from 'utils/format-helper';

const INVESTMENT_ENTITIES_QUERY = gql(`
  query FetchInvestmentEntitiesForCommitment {
    dashboard {
      id
      investmentEntities {
        id
        name
        status
        type
        createdAt
        updatedAt
      }
      lastUsedInvestmentEntity {
        id
      }
    }
  }
`);

const INVEST_IN_DEAL_MUTATION = gql(`
  mutation InvestInDeal(
    $dealId: ID!
    $amount: Float!
    $investmentEntityId: ID!
    $fundingSource: FundingSourceEnumType!
    $addToSyndicate: Boolean!
  ) {
    createClosing(
      dealId: $dealId
      amount: $amount
      investmentEntityId: $investmentEntityId
      fundingSource: $fundingSource
      addToSyndicate: $addToSyndicate
    ) {
      id
      deal {
        id
        companyInformation {
          id
          company {
            id
            name
          }
        }
      }
      investmentEntity {
        id
        name
      }
      amount
      status
      createdAt
      updatedAt
    }
  }
`);

const InvestInDealDialogButton: FC<{
  dealId: string;
  minimumInvestment: number;
  syndicateName: string;
  showSyndicateApplicationOption: boolean;
}> = ({ dealId, minimumInvestment, syndicateName, showSyndicateApplicationOption }) => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITIES_QUERY);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createClosing, { loading: investLoading, error: investError }] = useMutation(
    INVEST_IN_DEAL_MUTATION,
    {
      refetchQueries: ['getCommitmentsOfUserForDeal'],
    }
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntities = data.dashboard.investmentEntities;
  const lastUsedInvestmentEntity = data.dashboard.lastUsedInvestmentEntity;

  if (!investmentEntities || !investmentEntities[0])
    return <p>Create an investment entity first before accessing deals</p>;

  return (
    <>
      <FormPanel
        loading={investLoading}
        error={investError}
        onSubmit={data => {
          createClosing({
            variables: {
              dealId: dealId,
              amount:
                data.fundingSource === FundingSourceEnumType.IndianAccount
                  ? data.amount
                  : data.amountForNRAccounts,
              investmentEntityId: data.investmentEntityId,
              fundingSource: data.fundingSource,
              addToSyndicate: data.addToSyndicate === 'true',
            },
          }).then(() => {
            navigate(`/invest/deal/${dealId}`);
          });
        }}
        buttonRef={buttonRef}
      >
        <FormInput
          type="currency"
          fieldName="amount"
          label="Amount"
          defaultValue={minimumInvestment}
          helperText={`Minimum investment amount is ${formatCurrencyToShort(minimumInvestment)}`}
          validators={{
            required: true,
            minValue: minimumInvestment,
          }}
          conditionsToShow={{
            matches: [{ field: 'fundingSource', condition: '===', value: 'INDIAN_ACCOUNT' }],
          }}
        />
        <FormInput
          type="currency"
          fieldName="amountForNRAccounts"
          label="Amount"
          defaultValue={1000000}
          helperText={`Minimum investment amount is ₹10L for NR accounts.`}
          validators={{
            required: true,
            minValue: 1000000,
          }}
          conditionsToShow={{
            matches: [{ field: 'fundingSource', condition: '!==', value: 'INDIAN_ACCOUNT' }],
          }}
        />
        <FormInput
          type="select"
          fieldName="investmentEntityId"
          label="Investment Entity"
          options={investmentEntities.map(investmentEntity => ({
            label: investmentEntity?.name,
            value: investmentEntity?.id,
          }))}
          defaultValue={lastUsedInvestmentEntity?.id || investmentEntities[0].id}
          validators={{
            required: true,
          }}
          span="3"
        />
        <FormInput
          type="select"
          fieldName="fundingSource"
          label="Funding Source"
          options={[
            {
              label: 'Indian Bank Account',
              value: 'INDIAN_ACCOUNT',
            },
            {
              label: 'NRO/FCNR (B) Account',
              value: 'NON_RESIDENT_ORDINARY_ACCOUNT',
            },
            {
              label: 'NRE Account',
              value: 'NON_RESIDENT_EXTERNAL_ACCOUNT',
            },
            {
              label: 'Foreign Bank Account',
              value: 'FOREIGN_ACCOUNT',
            },
          ]}
          defaultValue={'INDIAN_ACCOUNT'}
          validators={{
            required: true,
          }}
          span="3"
        />
        <div className="col-span-6">
          <p className="block text-sm font-medium text-gray-900 pt-1.5 mb-2">
            Terms and Conditions
          </p>
          <div className="w-full h-64 border border-gray-300 rounded-lg overflow-auto p-2 text-sm text-gray-500">
            <ul className="list-decimal list-inside space-y-1">
              <li>
                I/We agree to the provisions of AL Trust, a SEBI-registered category I Angel Fund
                (“Fund”) and the subject investment, including:
              </li>
              <li>
                I/ We attest that I/ we qualify as an eligible ‘Angel Investor’ as per the SEBI
                (AIF) Regulations, 2012, and are competent to invest in the securities market as per
                applicable laws.
              </li>
              <li>
                I/We understand that as per the SEBI (AIF) Regulations, 2012, I/we need to invest a
                minimum of INR 25 lakhs over a period of five years from the date of signing of the
                contribution agreement.
              </li>
              <li>
                I/We understand that I/ We are not investing directly in the investee company, but
                in a SEBI-registered Angel Fund, which will, in turn, invest in the investee
                company.
              </li>
              <li>
                I/we acknowledge that we do not have any direct investor rights in the investee
                company (e.g. I/ We cannot contact the investee company directly)
              </li>
              <li>
                When direct shareholders of the investee company are required to vote or give
                approvals, the Lead Angel Investor in consultation with the investment manager of
                the Fund will decide/ vote on such matters (except in limited circumstances)
              </li>
              <li>I/We understand that there may be setup costs that I pay to AngelList India</li>
              <li>
                I/We agree and acknowledge that I/we do not have a family connection with any of the
                founders, promoters, or directors of the investee company
              </li>
              <li>
                I/We understand that if I back out after signing these closing terms, my investor
                access to the AngelList India platform may be revoked
              </li>
              <li>
                I/We understand that it is my/our responsibility to conduct thorough due diligence,
                carefully review the deal terms and investment documents while considering all risk
                factors, and ask any relevant questions before making an investment decision. I/we
                acknowledge that investing alongside notable investors does not guarantee a
                successful outcome
              </li>
              <li>
                I/We understand that the Fund’s investment manager and certain other investors may
                have access to material information regarding the investee company that may not have
                been shared with me/ us
              </li>
              <li>
                The Lead Angel Investor reserves the right to reject my/our subscription to this
                investment.
              </li>
              <li>
                I/We understand that my/our subscription amount is not guaranteed and the final
                investment amount accepted by the Fund may be less than my/our desired subscription
                amount. Excess amounts, if any will be returned
              </li>
              <li>I/ We understand that units shall be issued in dematerialised form only</li>
              <li>
                I/ We understand that this investment shall be subject to a mandatory lock-in period
                of 1 year as per the SEBI (AIF) Regulations, 2012{' '}
              </li>
              <li>
                I/We will pay carrypercentage total carry. I/ We understand that certain other
                investors may pay higher or lower carry at the discretion of the Lead Angel Investor
                in consultation with the investment manager of the Fund
              </li>
              <li>
                All communications may be made via email, and there may not be any communications at
                all before a liquidity or taxable event for the Fund
              </li>
              <li>
                I/ We understand that the units I/ we hold can only be transferred under limited
                circumstances and require approval of the Fund's investment manager. Should a
                transfer occur, I/ we agree and acknowledge that the process will involve specific
                documentation, procedures, and associated costs
              </li>
              <li>
                I/ We understand that any reference to the applicable law includes reference to
                subsequent amendments, rules, regulations and notifications under such law
              </li>
              <li>
                I/ We fully understand the above declarations and if needed, have sought guidance
                from advisors or legal counsel to clarify its meaning and intent.{' '}
              </li>
              <li>
                I/ We acknowledge that I/ we have fully read and agree to abide by the terms of the
                contribution agreement (available in the documents section under the heading
                ‘Financial Reports’)
              </li>
            </ul>
          </div>
          <div className="mt-2 border-l-4 border-gray-400 bg-gray-50 p-2">
            <p className="text-gray-700 text-xs max-w-xl">
              A one-time setup fee is payable in addition to the investment amount. Details will be
              shared later with the drawdown notice.
            </p>
          </div>
        </div>
        <FormInput
          type="checkbox-group"
          fieldName="terms"
          label=""
          options={[
            {
              label: 'I agree to the above terms and conditions',
              value: 'agree',
            },
          ]}
          defaultValue={[]}
          validators={{
            required: true,
          }}
          compact={true}
        />
        {showSyndicateApplicationOption ? (
          <FormInput
            type="radio-group"
            fieldName="addToSyndicate"
            label={`Do you want to apply to become a part of the ${syndicateName} network ?`}
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            defaultValue={showSyndicateApplicationOption ? 'true' : 'false'}
            validators={{
              required: true,
            }}
            compact={true}
          />
        ) : (
          <></>
        )}
      </FormPanel>
      <div className="grid grid-cols-2 mt-4">
        <Button
          className="w-full"
          loading={investLoading}
          onClick={() => {
            // @ts-ignore
            buttonRef.current?.submit();
          }}
          variant="primary"
        >
          Invest
        </Button>
      </div>
    </>
  );
};

export default InvestInDealDialogButton;
